<template>
  <div>
    <header>
      <nav class="sticky-nav">
        <router-link to="/">
          <div class="logo-container">
              <div class="logo">
                <img src="./assets/air_surgical_logo.png">
              </div>
          </div>
        </router-link>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </nav>
    </header>
    <!-- Router view for rendering content -->
    <router-view></router-view>
    <footer>
      <p class="copyright">&copy; 2025 Air Surgical. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped>
.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 10px 0px;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.logo img {
  height: 50px;
  margin: 0px;
}

.blue-text {
  color: #66a3ff;
  font-weight: bold;
  font-size: 20px;
}

.bold-text {
  font-weight: bold;
  font-size: 20px;
  margin-left: -3px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

ul {
  list-style-type: none;
  padding: 0 15px;
  margin: 0;
}

li {
  display: inline;
  margin-left: 20px;
}

a {
  color: #000;
  text-decoration: none;
}

footer {
  background-color: white;
  color: #1e1e1e;
  padding: 20px 0;
  text-align: center;
}

footer p {
  font-size: 14px;
  font-weight: normal;
}
</style>
